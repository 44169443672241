<template>
  <p
    v-if="limitedUploads"
    class="error-area"
  >
    This account is currently operating as a trial, there is a limit of 10 documents that may exist in the platform at any time.
  </p>
  <ul
    class="upload-list"
    :class="{'upload-list-v2': v2}"
  >
    <li class="upload-item">
      <div class="num">
        <span>1</span>
      </div>
      <div class="content">
        <h3 class="upload-section-title">
          Select Document Type
        </h3>
        <select-input
          v-if="!v2"
          :key="selectPlaceholder"
          v-model="documentType"
          select-id="document-selector"
          :select-placeholder="selectPlaceholder"
          name="document types"
          :options="documentTypesToDisplayMapping"
        />
        <Multiselect
          v-else
          class="multiselect-v2"
          :model-value="documentType"
          :options="Object.keys(documentTypesToDisplayMapping).map((id) => ({id, label: documentTypesToDisplayMapping[id]}))"
          :loading="!selectIsReady"
          :placeholder="selectPlaceholder"
          track-by="id"
          label="label"
          @update:model-value="(value) => documentType = value"
        />
      </div>
    </li>

    <li class="upload-item">
      <div class="num">
        <span>2</span>
      </div>
      <upload-area
        :upload-area-state="uploadListState"
        :show-prepared-documents="showPreparedDocuments"
        :document-type="documentType.id"
      />
    </li>

    <li
      class="upload-item"
    >
      <div class="num">
        <span>3</span>
      </div>
      <div class="content">
        <div class="content-flex">
          <h3
            style="margin-right: 4px"
            class="upload-section-title"
          >
            Extract Data
          </h3>
          <span
            v-if="!v2"
            v-tooltip="'Refresh documents'"
            class="icon-container"
            @click.prevent="triggerResetTimer"
          >
            <rotate-cw-icon
              size="1.3x"
              :stroke="'black'"
            />
          </span>
          <ActionButtonRefresh
            v-else
            size="xs"
            variant="TERTIARY"
            :icon-only="true"
          >
            <template #icon>
              <rotate-cw-icon />
            </template>
          </ActionButtonRefresh>
        </div>
        <document-intake-progress
          ref="documentIntakeProgressRef"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import isSet from '@/store/helpers/isSet';
import UploadArea from '@/components/uploads/UploadArea.vue';
import { RotateCwIcon } from '@zhuowenli/vue-feather-icons';
import Multiselect from 'vue-multiselect';
import DocumentIntakeProgress from './DocumentIntakeProgress.vue';
import SelectInput from '../forms/SelectInput.vue';
import { allowedStates } from '../../store/helpers/storeState';
import ActionButtonRefresh from '../general/buttons/ActionButtonRefresh.vue';

export default {
  components: {
    ActionButtonRefresh,
    UploadArea,
    Multiselect,
    DocumentIntakeProgress,
    SelectInput,
    RotateCwIcon,
  },
  props: {
    v2: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    documentType: '',
    toast: useToast(),
    uploadListState: allowedStates.IS_LOADING,
  }),
  computed: {
    ...mapGetters({
      documentTypesStoreStatus: 'documentTypes/storeStatus',
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
      isTrial: 'localisation/isTrial',
      limitedUploads: 'localisation/limitedUploads',
    }),
    selectPlaceholder() {
      const isReadyPlaceholder = this.v2 ? '-- Select Option --' : 'Select document type';
      return this.selectIsReady ? isReadyPlaceholder : 'Loading available document types...';
    },
    selectIsReady() {
      return this.documentTypesStoreStatus === allowedStates.IS_READY;
    },
    showPreparedDocuments() {
      const preparedQueryParam = this.$route.query.prepared;
      if (isSet(preparedQueryParam)) {
        return preparedQueryParam === 'true';
      }

      return this.isTrial;
    },
    allowedStates() {
      return allowedStates;
    },
  },
  mounted() {
    const promises = [this.getDocumentTypes(), this.getSettings()];
    this.uploadListState = allowedStates.IS_LOADING;
    Promise.all(promises)
      .then(() => {
        const documentTypeKeys = Object.keys(this.documentTypesToDisplayMapping);
        if (documentTypeKeys.length === 1) {
          [this.documentType] = documentTypeKeys;
        }
        this.uploadListState = allowedStates.IS_READY;
      })
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching document types');
        this.uploadListState = allowedStates.IS_ERRORING;
      });
  },
  methods: {
    ...mapActions({
      getDocumentTypes: 'documentTypes/lazyGet',
      getSettings: 'localisation/lazyInit',
    }),
    triggerResetTimer() {
      this.$refs.documentIntakeProgressRef.resetTimer();
    },
  },
};
</script>
<style lang="scss">
.upload-list-v2 {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin: 0;

  .upload-item {
    display: flex;
    gap: 8px;
    margin: 0;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .content-flex {
      display: flex;
      align-items: CENTER;
      gap: 8px;
    }

    .num {
      margin: 2px 0 0 0;
      border-radius: 100000px;
      display: flex;

      span {
        margin-bottom: 7px;
      }
    }
  }

  .upload-section-title {
    margin: 0 !important;

    color: var(--foreground-_Primary, #1A2C2D);
    font-family: Lato !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
  }

  .upload-section-body-text {
    color: var(--foreground-_Primary, #1A2C2D);
    margin: 0;

    /* Body/Body 3 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  .upload-loading-area-placeholder {
    height: 42px;
    border-radius: var(--Radius-Radius-2, 8px);
    border: var(--Stroke-width-Width-4, 1.5px) dashed var(--stroke-_focus, #B3C5C6);
    background: var(--background-System-_secondary-A, #F7FAFA);
  }

  .content {
    min-width: 380px;
  }
}

.upload-list-v2 .progress-stack-list {
  .progress-stack-item, .error-area {
    color: var(--foreground-_Primary, #1A2C2D);
    /* Labels/Labels 2 */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .progress-bar {
    background-color: #FF9D00;
  }
  .document-actions {
    display: flex;
    gap: 8px;
  }
}
</style>
