<template>
  <div :class="`progress ${currentValue < totalValue && 'progress-striped active'}`">
    <div
      :style="`width: ${percentage}%; min-width: ${percentage}`"
      class="progress-bar"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    totalValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    percentage() {
      return (this.currentValue / this.totalValue) * 100;
    },
  },
  watch: {
    value(newValue) {
      this.animateProgressBar(newValue);
    },
  },
  methods: {
    /**
     * Animate the progress bar to the specified new value.
     * @param {number} newValue - The new value for the progress bar.
     *                           Should be a number between 0 and 100.
     */
    animateProgressBar(newValue) {
      const animationDuration = 500; // Animation duration in milliseconds
      const startValue = this.currentValue;
      const endValue = newValue;

      let startTime = null;
      const animate = (timestamp) => {
        if (!startTime) {
          startTime = timestamp;
        }

        // Calculate the progress and percentage of the animation.
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / animationDuration, 1);

        // Calculate the current value based on the start and end values and the animation progress.
        const currentValue = Math.round(startValue + (endValue - startValue) * percentage);

        // Update the current value of the progress bar.
        if (progress < animationDuration) {
          this.currentValue = currentValue;
          requestAnimationFrame(animate);
        } else {
          this.currentValue = endValue;
        }
      };

      // Start the animation.
      requestAnimationFrame(animate);
    },
  },
};
</script>
